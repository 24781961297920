body {
  padding: 0;
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

header {
  display: flex;
  gap: 8px;
  height: 40px;
  padding: 8px 1rem;
  background-color: #1e1e2e;
  align-items: center;
}

header > figure {
  display: flex;
  gap: 4px;
  color: aliceblue;
  align-items: center;
}

header > figure > img {
  width: 100%;
  max-width: 32px;
  height: 100%;
}